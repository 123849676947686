import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Axios from 'axios'
import { Form, Col, Row, Input, FormGroup, CardBody, Button } from 'reactstrap'
import { log} from 'util';
import ReactGA from 'react-ga4';

function GoogleAnalytic(){
   // ReactGA.initialize( ' UA-000000-01 ');
    // ReactGA.pageview("/");
    ReactGA.send({ hitType: "pageview", page: "/", title: "Fundo Zapallar - Formulario" });
}

const contactoReferencia = {
    pyg: "pyg",
    leria:"leria"
}
const setContactoReferencia = value => {
    if(value === "pyg"){
        return contactoReferencia.pyg
    }
    if(value === "leria"){
        return contactoReferencia.leria
    }else {
        return null
    }
}
class Formulario extends Component {
    constructor(props){
        super(props)
        this.state={
            loading:false,
            textNombre:'',
            textApellido:'',
            textEmail:'',
            textMensaje:'',
            valueRadio:'loteo'
        }
    }
    onChangeText = event => {
        this.setState({ [event.target.name]: event.target.value })
    }
    onChangeRadio = value => {
        this.setState({ valueRadio: value })
    }
    onEnviarFormulario = (event) => {
        const { history } = this.props;
        const { textNombre, textApellido, textEmail, textMensaje, valueRadio } = this.state
        const nombreCompleto = textNombre + ' ' + textApellido
        this.setState({loading:true})
        event.preventDefault();

        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let token = 'b7ab9aecd3919ad85037904dbd6b14de';
        
        /* const data = { name: nombreCompleto, email: textEmail, message: textMensaje, contactoReferencia: setContactoReferencia(valueRadio) ,time: this.props.firebase.fieldValue.serverTimestamp() } */
        const data = { name: nombreCompleto, email: textEmail, message: textMensaje, contactoReferencia: setContactoReferencia(valueRadio) ,time: date +"-"+ month +"-"+ year, token: token}
        Axios.post('/sendmail.php', data).then(res=>{
            //ga('send', 'event', 'Web', 'Envío Form', 'Form web');
            // console.log(res)
            if (res.data == 'ok') {
                // GoogleAnalytic();
                ReactGA.event({
                    category: 'Envío Form',
                    action: 'Form web'
                });
                history.push("/gracias");
            }

        });

        this.setState({ 
            textNombre: '',
            textApellido:'',
            textEmail:'',
            textMensaje:'',
            valueRadio:''
        }) 
        
    }
    
    render(){
        const { loading, textNombre, textApellido, textEmail, textMensaje, valueRadio } = this.state
        const isInvalid = textNombre === '' || textApellido === '' || textEmail ==='' || textMensaje === '' || valueRadio === ''
        return(
            <Form onSubmit={this.onEnviarFormulario}>
                <CardBody>
                    <Row>
                        <Col md="6">
                            <FormGroup className="label-floating">
                                <label className="control-label">
                                    Nombre
                                </label>
                                <Input
                                    name="textNombre"
                                    value={textNombre}
                                    onChange={this.onChangeText}
                                    placeholder="Tu Nombre"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup className="label-floating">
                                <label className="control-label">
                                    Apellido
                                </label>
                                <Input
                                    name="textApellido"
                                    value={textApellido}
                                    onChange={this.onChangeText}
                                    placeholder="Tu Apellido"
                                    type="text"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Email 
                        </label>
                        <Input
                            name="textEmail"
                            value={textEmail}
                            onChange={this.onChangeText}
                            placeholder=" Tu Email"
                            type="email"
                        />
                    </FormGroup>
                    {/* <FormGroup>
                        <Radio value={valueRadio} onRadioChange={this.onChangeRadio}>
                            Deseo contactar a:
                        </Radio>
                    </FormGroup> */}
                    <FormGroup className="label-floating">
                        <label className="control-label">
                            Mensaje
                        </label>
                        <Input
                            id="message"
                            name="textMensaje"
                            value={textMensaje}
                            onChange={this.onChangeText}
                            placeholder="Tu mensaje"
                            type="textarea"
                            rows="6"
                        />
                    </FormGroup>
                    
                    <Row>
                        {/* <Col md="6">
                            <FormGroup check>
                                <Label check>
                                    <Input defaultValue="" type="checkbox" />
                                    No soy un robot!{" "}
                                    <span className="form-check-sign" />
                                </Label>
                            </FormGroup>
                        </Col> */}
                        <Col md="6">
                            <Button type ="submit" color="info" disabled={isInvalid}>
                                {loading && 
                                    <div className="uil-reload-css reload-small mr-1">
                                        <div />
                                    </div>
                                }
                                Enviar
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Form>
        )
    }
}

export default withRouter(Formulario)