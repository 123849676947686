import React from 'react'
import { Container, Row } from 'reactstrap'
import { PhotoSwipeGallery } from 'react-photoswipe'
let options = {
  //http://photoswipe.com/documentation/options.html
}

let getThumbnailContent = item => {
  return (
    <div
      className="gallery-item"
      itemProp="associatedMedia"
      itemScope=""
      itemType="http://schema.org/ImageObject"
    >
      <a data-size="750x1002" href={item.src} itemProp="contentUrl">
        <img
          alt="..."
          className="vertical-image img-rounded img-responsive"
          itemProp="thumbnail"
          src={item.src}
        />
      </a>
      <figcaption className="gallery-caption" itemProp="caption description">
        {item.title}
      </figcaption>
    </div>
  )
}
const photoSwipeItems = [
  {
    src: require("assets/img/gallery/gal1.jpg"),
    title: "",
    w: "1024",
    h: "768"
  },
  {
    src: require("assets/img/gallery/gal2.jpg"),
    title: "",
    w: "1024",
    h: "768"
  },
  {
    src: require("assets/img/gallery/gal3.jpg"),
    title: "",
    w: "1024",
    h: "768"
  },
  {
    src: require("assets/img/gallery/gal4.jpg"),
    title: "",
    w: "1024",
    h: "768"
  },
  {
    src: require("assets/img/gallery/gal5.jpg"),
    title: "",
    w: "1024",
    h: "768"
  },
  {
    src: require("assets/img/gallery/gal6.jpg"),
    title: "",
    w: "1024",
    h: "768"
  },
  {
    src: require("assets/img/gallery/gal7.jpg"),
    title: "",
    w: "1024",
    h: "768"
  },
  {
    src: require("assets/img/gallery/gal8.jpg"),
    title: "",
    w: "1024",
    h: "768"
  }
];
const Galeria = () => {
  return (
    <div className="section text-center galeria" id="galeria">
      <Container>
        <div className="row mx-0">

          <PhotoSwipeGallery
            items={photoSwipeItems}
            options={options}
            thumbnailContent={getThumbnailContent}
          />
        </div>
      </Container>
    </div>
  )
}
export default Galeria